import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
  return (
    <Layout>
    <Head title="Contact" />
    <h1>Contact me</h1>
    <p>Form to contact me goes here</p>
    <p>Need a developer? <a href="https://twitter.com" >Tweet me</a></p>
    </Layout>
  )
}

export default ContactPage